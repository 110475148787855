<template>
  <main class="main--not-logged">
    <div class="banner">
      <img
        :src="require('../assets/img/architecture-g19c3e4465_1920_low.jpg')"
        class="banner__img"
      />
    </div>
    <div class="main-content">
      <shout-box
        :data="shoutBoxData"
      >
        <template slot="custom-content">
          <div class="shoutbox__custom-content">
            <form-login />
          </div>
        </template>
      </shout-box>
    </div>
    <footer-component />
  </main>
</template>

<script>
const FormLogin = () => import('@/components/forms/FormLogin.vue');
const ShoutBox = () => import('@/components/ShoutBox.vue');
const FooterComponent = () => import('@/components/layout/FooterComponent.vue');

export default {
  name: 'Login',
  components: {
    FormLogin,
    ShoutBox,
    FooterComponent,
  },
  data() {
    return {
      shoutBoxData: {
        title: 'Accedi o <span class="text-warning">registrati</span> per utilizzare tutti i servizi di MF Solvency',
        subtitle: 'Certifica la tua affidabilità nella gestione dei rapporti commerciali'
          + ' e nella gestione dei pagamenti',
      },
    };
  },
  metaInfo() {
    return {
      title: 'Accesso utenti - MF Solvency',
      meta: [
        { property: 'og:title', content: 'Accesso utenti - MF Solvency' },
        { property: 'og:site_name', content: 'MF Solvency' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  mounted() {
    if (this.$store.getters['auth/isLogged']) {
      this.$router.replace('/dashboard-utente');
      return;
    }
    // console.debug('Home setpreloader', false);
    this.$store.dispatch('setPreloader', false);
  },
};
</script>
